
//store mutations 预留

export default {
  increment(state) {
    // 变更状态
    // state.count++
  },
  setScrollMain(state, flag) {
    state.isScrollMain = flag;
  }
}