import Vue from 'vue'

// import AxiosPlugin from '../../axios/axios.js'
// Vue.use(AxiosPlugin, '$axios');

// import HYConfig from '../../config/config.js'
// Vue.use(HYConfig)

// import VueCookies from 'vue-cookies'
// Vue.use(VueCookies)

const state = {
  lang: localStorage.lang || 'cn',
}



const mutations = {
  //更新
  UPDATE_LANG(state, lang) {
    // sessionStorage.setItem('account', newUserInfo.account);
    if(!lang || ''==lang){
      lang = localStorage.lang || 'cn';
    }
    // let lang = localStorage.lang || 'cn';
    localStorage.setItem("lang",lang);
    state.lang = lang;
    // state.role = role;
  },
}

const getters = {
  getLang() {
    // let lang = localStorage.lang || 'cn';
    // state.lang = lang;
    return state.lang;
  },
  getLangMsg(){
    
    let tipsEn = {
      title:"Tips",
      noLogin:"Do you need to log in now?",
      noParnter:"Partner certification is required. Do you need to do it immediately?",
      noEn:"Need enterprise certification, go to certification immediately?",
      noShop:"Need to be certified by the merchant, go to the certification immediately?",
      noShopIsParnter:"Please enter from the Partner Center - My Recommendation module",
			noWhAuth:"You don't have HYTX warehouse permission yet. Do you want to apply?",
    };
    let tipsCn = {
      title:"提示",
      noLogin:"需要登录，立即去登录?",
      noParnter:"需要进行合伙人认证，立即去认证?",
      noEn:"需要进行厂家认证，立即去认证?",
      noShop:"需要进行商户认证，立即去认证?",
      noShopIsParnter:"请从‘合伙人中心-我的推荐’模块进入",
			noWhAuth:"您还没有互易仓权限，去申请吗？",
    };
    let showTips = tipsCn;
    let lang = state.lang;
    // console.log(lang);
    if('en'===lang){
      showTips = tipsEn;
    }
    return showTips;
  },
}

const actions = {
  //更新
  UPDATE_LANG: ({ commit }, lang) => {
    commit('UPDATE_LANG', lang);
  },
}

export default {
  state,
  mutations,
  getters,
  actions
}
