
/*请求地址配置*/


// 
// const HY_BASEURL = "http://192.168.16.208:8090";

//跨域本地代理
//开发地址
// const HY_BASEURL = "http://www.hytx.com:8082";

 // const HY_BASEURL = "/baseUrl";

// 测试地址
// const HY_BASEURL = "http://47.98.186.55";
// const HY_BASEURL = "http://47.98.163.184:8084";

// 线上地址

//const HY_BASEURL = "";


//我的ngix本地
// const HY_BASEURL = "http://localhost:8888";
// const HY_BASEURL = "http://192.168.16.221:8090";

// const HY_BASEURL = "http://192.168.16.113:8090";

// const HY_BASEURL = "http://192.168.16.116:8090";


const Validate = {
	//判断是否是正整数
	RegInteger: /^[0-9]*$/,
	//判断是否是负整数
	RegInteger01: /-[1-9]\d*$/,
	//判断是否是整数
	RegInteger02: /(^[0-9]*$)|(^-[1-9]\d*$)/,
	//判断是否是整数或者小数
	RegNumber: /(^[0-9]*$)|(^[0-9]+\.?[0-9]*$)/,
	//判断是否是非零正整数
	RegIntegerNoZero:/^\+?[1-9][0-9]*$/,
	//判断是否是非零数字
	RegNumberNoZero: /(^\+?[1-9][0-9]*$)|(^[0-9]+\.?[0-9]*$)/,
	//判断是否是六位小数
	RegNumberDecimal6 : /^[0-9]+\.?[0-9]{0,6}$/,
	//判断是否是四位小数
	RegNumberDecimal4 : /^[0-9]+\.?[0-9]{0,4}$/,
	//判断是否是三位小数
	RegNumberDecimal3 : /^[0-9]+\.?[0-9]{0,3}$/,
	
	//判断是否是二位小数
	RegNumberDecimal2 : /^[0-9]+\.?[0-9]{0,2}$/,
	//判断是否是二位小数，可为负数
	RegNumberMinusAndDecimal2 : /(^[0-9]+\.?[0-9]{0,2}$)|(^-[0-9]+\.?[0-9]{0,2}$)/,
	//判断是否是一位小数
	RegNumberDecimal1 : /^[0-9]+\.?[0-9]{0,1}$/,
	//验证身份证号
	RegUserCode: /^\d{15}|\d{18}$/,
	/** 密码-正则表达式 ：6-18位，必须包含一位字母、必须包含一位数字*/
	REGEX_PASSWORD : /^(?![0-9]+$)(?![a-zA-Z]+$)([0-9A-Za-z]|[~!@#$%^&*+=]){6,18}$/,
	/** 手机号码-正则表达式 eg:18755409000 */
	REGEX_PHONE : /^1\d{10}$/,
	/** 国际手机号码-正则表达式 6-11位数字 */
	REGEX_PHONE_International : /^\d{6,11}$/,
	/** 电话号码-正则表达式 eg:021-6646871  0216646871 */
	REGEX_TELEP : /^(\d{3,4}-)?\d{7,8}$/,
	/** 客服电话-正则表达式 eg:400-800-5520 400-8005520 4008005520*/
	REGEX_CUSTOMER : /^(\d{3}-)(\d{3}-)\d{4}$/,
	/** 十五位身份证号码-正则表达式 */
	REGEX_IDCARD_15 : /^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}$/,
	/** 十八位身份证号码-正则表达式 */
	REGEX_IDCARD_18 : /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
	/** 汉字和大写字母 */
	REGEX_ChineseAndLetter : /^([\u4e00-\u9fa5]|[A-Z])+$/,
	/** 邮箱-正则表达式 */
	REGEX_EMAIL : /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/,


	
	

}

// const Vue =  {
//   install: function(Vue) {
//     // Do stuff
//   }
// }

// export default {
// 	urlConfig
// }
export default {
  install: function(Vue) {
    Object.defineProperty(Vue.prototype, '$Validate', { value: Validate });
  }
}