// import Vue from 'vue'
// import App from './App.vue'

// Vue.config.productionTip = false

// new Vue({
//   render: h => h(App),
// }).$mount('#app')

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
// const Vue = require('vue')

// import App from './index.vue'
import App from './App.vue'

import router from './router/index.js'
import store from './vuex/store'

// import jquery from 'jquery'

// 20191206-过滤器
// import moneyFormat from '../../filters/moneyFormat'
// Vue.filter('moneyFormat', moneyFormat)


import AxiosPlugin from './axios/axios.js'
import MetaInfo from 'vue-meta-info'

// import RunConfig from './config/runConfig/index.js'

import Validate from './common/validate.js'

import Utils from './common/utils.js'
import elPickerDate from './common/elPickerDate.js'
// import wxUtils from '../../common/wxUtils.js'

import i18n from './i18n/i18n'

import onEvent from './plugins/onEvent'

Vue.use(MetaInfo)

// import AxiosPlugin from './axios.js';
Vue.use(AxiosPlugin, '$axios');

// Vue.use(RunConfig)

Vue.use(Validate)

Vue.use(Utils)
Vue.use(elPickerDate)
// Vue.use(wxUtils)

//挂载swiper

// require('swiper/dist/css/swiper.css')
// import VueAwesomeSwiper from 'vue-awesome-swiper';
// Vue.use(VueAwesomeSwiper);

// console.log(HYConfig);

import ElementUI from 'element-ui'
// 默认element主题
import 'element-ui/lib/theme-chalk/index.css'
//自定义element主题
// import './assets/element-variables.scss'
//element ui 全部引用
Vue.use(ElementUI)

// element ui 部分引用
// import {
//   Pagination,
//   Dialog,
//   Autocomplete,
//   Dropdown,
//   DropdownMenu,
//   DropdownItem,
//   Menu,
//   Submenu,
//   MenuItem,
//   MenuItemGroup,
//   Input,
//   InputNumber,
//   Radio,
//   RadioGroup,
//   RadioButton,
//   Checkbox,
//   CheckboxButton,
//   CheckboxGroup,
//   Switch,
//   Select,
//   Option,
//   OptionGroup,
//   Button,
//   ButtonGroup,
//   Table,
//   TableColumn,
//   DatePicker,
//   TimeSelect,
//   TimePicker,
//   Popover,
//   Tooltip,
//   Breadcrumb,
//   BreadcrumbItem,
//   Form,
//   FormItem,
//   Tabs,
//   TabPane,
//   Tag,
//   Tree,
//   Alert,
//   Slider,
//   Icon,
//   Row,
//   Col,
//   Upload,
//   Progress,
//   Spinner,
//   Badge,
//   Card,
//   Rate,
//   Steps,
//   Step,
//   Carousel,
//   CarouselItem,
//   Collapse,
//   CollapseItem,
//   Cascader,
//   ColorPicker,
//   Transfer,
//   Container,
//   Header,
//   Aside,
//   Main,
//   Footer,
//   Timeline,
//   TimelineItem,
//   Link,
//   Divider,
//   Image,
//   Calendar,
//   Backtop,
//   PageHeader,
//   CascaderPanel,
//   Loading,
//   MessageBox,
//   Message,
//   Notification
// } from 'element-ui';

// Vue.use(Pagination);
// Vue.use(Dialog);
// Vue.use(Autocomplete);
// Vue.use(Dropdown);
// Vue.use(DropdownMenu);
// Vue.use(DropdownItem);
// Vue.use(Menu);
// Vue.use(Submenu);
// Vue.use(MenuItem);
// Vue.use(MenuItemGroup);
// Vue.use(Input);
// Vue.use(InputNumber);
// Vue.use(Radio);
// Vue.use(RadioGroup);
// Vue.use(RadioButton);
// Vue.use(Checkbox);
// Vue.use(CheckboxButton);
// Vue.use(CheckboxGroup);
// Vue.use(Switch);
// Vue.use(Select);
// Vue.use(Option);
// Vue.use(OptionGroup);
// Vue.use(Button);
// Vue.use(ButtonGroup);
// Vue.use(Table);
// Vue.use(TableColumn);
// Vue.use(DatePicker);
// Vue.use(TimeSelect);
// Vue.use(TimePicker);
// Vue.use(Popover);
// Vue.use(Tooltip);
// Vue.use(Breadcrumb);
// Vue.use(BreadcrumbItem);
// Vue.use(Form);
// Vue.use(FormItem);
// Vue.use(Tabs);
// Vue.use(TabPane);
// Vue.use(Tag);
// Vue.use(Tree);
// Vue.use(Alert);
// Vue.use(Slider);
// Vue.use(Icon);
// Vue.use(Row);
// Vue.use(Col);
// Vue.use(Upload);
// Vue.use(Progress);
// Vue.use(Spinner);
// Vue.use(Badge);
// Vue.use(Card);
// Vue.use(Rate);
// Vue.use(Steps);
// Vue.use(Step);
// Vue.use(Carousel);
// Vue.use(CarouselItem);
// Vue.use(Collapse);
// Vue.use(CollapseItem);
// Vue.use(Cascader);
// Vue.use(ColorPicker);
// Vue.use(Transfer);
// Vue.use(Container);
// Vue.use(Header);
// Vue.use(Aside);
// Vue.use(Main);
// Vue.use(Footer);
// Vue.use(Timeline);
// Vue.use(TimelineItem);
// Vue.use(Link);
// Vue.use(Divider);
// Vue.use(Image);
// Vue.use(Calendar);
// Vue.use(Backtop);
// Vue.use(PageHeader);
// Vue.use(CascaderPanel);

// Vue.use(Loading.directive);

// Vue.prototype.$loading = Loading.service;
// Vue.prototype.$msgbox = MessageBox;
// Vue.prototype.$alert = MessageBox.alert;
// Vue.prototype.$confirm = MessageBox.confirm;
// Vue.prototype.$prompt = MessageBox.prompt;
// Vue.prototype.$notify = Notification;
// Vue.prototype.$message = Message;

/*基于，element-ui分页组件，自定义分页组件*/
import hyPagePagination from './components/Common/HyPagePagination'
Vue.use(hyPagePagination);
/*品牌展示组件*/
// import hyBrandListShow from './components/Common/HyBrandListShow'
// Vue.use(hyBrandListShow);
/*自定义省市县联动组件*/
// import hyCityCountyLinkage from './components/Common/HyCityCountyLinkage'
// Vue.use(hyCityCountyLinkage);
/*自定义省市县联动组件2,汇付天下，省区域*/
// import hyCityCountyLinkage2 from './components/Common/HyCityCountyLinkage2'
// Vue.use(hyCityCountyLinkage2);
/*自定义上传图片组件*/
// import hyUpLoadImg from './components/Common/HyUpLoadImg'
// Vue.use(hyUpLoadImg);
/*自定义上传文件组件*/
// import hyUpLoadFile from './components/Common/HyUpLoadFile'
// Vue.use(hyUpLoadFile);
// 基于，element-ui上传组件，自定义上传文件组件
// import hyElUpLoad from './components/Common/HyElUpLoad'
// Vue.use(hyElUpLoad);
/*自定义图片展示*/
// import imgShow from './components/Common/ImgShow'
// Vue.use(imgShow);
/*自定义图片展示*/
// import smallImgShow from './components/Common/SmallImgShow'
// Vue.use(smallImgShow);
/*自定义html转pdf*/
// import htmlToPdf from './components/Common/HtmlToPdf'
// Vue.use(htmlToPdf);

// //富文本编辑器样式
// import 'quill/dist/quill.core.css'
// import 'quill/dist/quill.snow.css'
// import 'quill/dist/quill.bubble.css'

//引入iconfont
require('./assets/css/iconfont/iconfont.css')
//引入自定义css
require('./assets/css/base.less')

require('./assets/css/login.less')

require('./assets/css/common.less')

// require('./assets/css/header.less')

// require('./assets/css/footer.less')

// require('./assets/css/home.less')

// require('./assets/css/user.less')

// require('./assets/css/shoppingCart.less')

require('./assets/css/marketTable.less')

require('./assets/css/buyer.less')

require('./assets/css/seller.less')

require('./assets/css/service_mod.less')
// require('../../assets/css/information.less')

// require('./assets/css/inforQuillEditor.less')

// require('./assets/css/finance.less')

// require('../../assets/css/commercialTenant.less')

/*移动端样式*/
// require('./assets/css/mobile.less')




Vue.config.productionTip = false

/* eslint-disable no-new */
// new Vue({
//   el: '#app',
//   router,
//   store,
//   i18n,
//   components: { App },
//   template: '<App/>',
//   /* 这句非常重要，否则预渲染将不会启动 */
//   mounted () {
//     document.dispatchEvent(new Event('render-event'));
//   }

// })
new Vue({
	router,
	store,
	i18n,
  render: h => h(App),
}).$mount('#app')