import Vue from 'vue'
import Vuex from 'vuex'

// const Vue = require('vue')
// const Vuex = require('vuex')

import actions from './actions.js'
import mutations from './mutations.js'

import login from './modules/login.js'
import catchData from './modules/catchData.js'
import menus from './modules/menus.js'
import langData from './modules/langData.js'

// import amazon from './modules/amazon.js'

Vue.use(Vuex)

//浏览器内容区基本高度
const baseWinHeight = window.innerHeight || document.body.clientHeight;
//表格高度
const tabelHeightCal = (offset) => {
	return (baseWinHeight - offset) > 300 ? (baseWinHeight - offset) : 300
}
const tabelHeightCal2 = (offset,minHeight) => {
	return (baseWinHeight - offset) > minHeight ? (baseWinHeight - offset) : minHeight
}

const store = new Vuex.Store({
	isScrollMain: true,
	state: {
		//部分接口和客户端有区分
		client: "wms",
		homePage:"Home",
		/**
		 * [isTestDev 是否是正式上线的]
		 * @type {Boolean}
		 *  前端/测试: true
		 *  线上: false
		 *
		 *
		 */
		isTestDev: false,
		/**
		 * 客服聊天
		 * */
		// onlineServiceBaseUrl: "https://hymsg.hytx.com/client.html#/client",
		/**
		 * 移动端URL
		 */
		// mobileBaseUrl: 'https://app.hytx.com',
		/**
		 * 移动商城
		 */
		// mobileCommerceUrl: 'https://app.hytx.com/',
		/**
		 * 移动端URL
		 */
		// appDownLoadUrl: 'https://app.hytx.com/AppFrame/AppSysDownLoad',
		// mobileBaseUrl:'http://192.168.16.200:8180',
		/**
		 *  汇付天下，form提交后跳转
		 *   前端/测试：https://eacloud.testpnr.com/api/publicRequests
		 *   线上
		 */
		// hftxCodeVerifyUrl:'https://eacloud.testpnr.com/api/publicRequests',
		/**
		 * [staticBaseUrl 基于static路径地址]
		 * @type {String}
		 * 前端: ""
		 * 线上：
		 */
		staticBaseUrl: "",
		/**
		 * [isMobile 是否是手机]
		 * @type {Boolean}
		 */
		isMobile: !!navigator.userAgent.match(
			/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
			),
		contenWidth: window.innerwidth || document.body.clientWidth,
		contenHeight: baseWinHeight,
		frameConHeight: tabelHeightCal(60),
		frameConHeight1: tabelHeightCal(60+120),
		frameConHeightWh: tabelHeightCal(60 + 220 -43),
		frameConHeightWh1: tabelHeightCal(60 + 220),
		frameConHeightWh2: tabelHeightCal(60 + 220+43),
		frameConHeightWh3: tabelHeightCal(60 + 220 + 56),
		frameConHeightWh4: tabelHeightCal(60 + 220 + 56 + 44),
		frameConHeightWh5: tabelHeightCal(60 + 220 + 56 + 44+110),
		
		frameConHeightWh8: tabelHeightCal2(60 + 220 + 56 + 44+110,200),
		frameConHeightWh9: tabelHeightCal2(60 + 220 + 56 + 44+110+250,150),
		frameConHeightSeller: tabelHeightCal(60 + 30),
		frameConHeightSeGoodsRea: tabelHeightCal(60 + 30 + 230),
		/*表格显示容器高度9账单专用*/
		tableMaxHeight9: tabelHeightCal(61 + 53 + 63 + 63 + 100 + 80),
		/**
		 * 客服电话
		 * 400-800-5550
		 */

		CusServiceTelText: '(+1)626-989-9999',
		// CusServiceTelText: '400-800-5550',
		/**
		 * 客服电话
		 * 4008005550
		 */

		// CusServiceTel:'4008005550',
		/**
		 * [ISIOS 是否是ios]
		 * @type {[type]}
		 */
		ISIOS: !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
		/*国际区号*/
		areaCodeList: [{
			label: '常用',
			options: [{
				code: '中国大陆',
				codeText: '86',
			}, {
				code: '中国香港',
				codeText: '852',
			}, {
				code: '中国台湾',
				codeText: '886',
			}, {
				code: '中国澳门',
				codeText: '853',
			}, {
				code: '美国',
				codeText: '1',
			}]
		}],
		author: 'nidun'
	},
	actions: actions,
	getters: {

	},
	mutations: mutations,
	modules: {

		login,
		catchData,
		menus,
		langData,
		// amazon

	},

})

export default store
