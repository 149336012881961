import Vue from 'vue'

import AxiosPlugin from '../../axios/axios.js'
Vue.use(AxiosPlugin, '$axios');

import HYConfig from '../../config/config.js'
Vue.use(HYConfig)

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)



const state = {
  userInfo: {},
  // whAuth: "0",//仓库权限
  // role:[],
  // shopInfo: {},
}

// const cookieDate = "30MIN";
// const cookieDate = 60*60*24;
const cookieDate = "24h";

//存cookie
// function setCookie(name, value) {
//   // var Days = 30;
//   var exp = new Date();
//   exp.setTime(exp.getTime() + 30 * 60 * 1000);//半个小时过期
//   document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString();
// }

// //取cookie
// function getCookie(name) {
//   var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
//   if (arr = document.cookie.match(reg)){
//   	return unescape(arr[2]);
//   } else {
//   	return null;
//   }

// }

// //删除cookie
// function delCookie(name) {
//   var exp = new Date();
//   exp.setTime(exp.getTime() - 1);
//   var cval = getCookie(name);
//   if (cval != null){
//   	document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
//   }
// }

const mutations = {
	
  //仓库权限
  UPDATE_WH_AUTH(state, userInfo) {
		let $cookies = Vue.prototype.$cookies;
    // $cookies.set("hy_user_whAuth",whAuth,cookieDate);
    // state.whAuth = whAuth;

    $cookies.set("hy_userInfo_isWh",userInfo.isWh,cookieDate);
    $cookies.set("hy_userInfo_whCusNo",userInfo.whCusNo,cookieDate);
    state.userInfo.isWh = userInfo.isWh;
    state.userInfo.whCusNo = userInfo.whCusNo;
  },
  //更新用户登录信息
  UPDATE_USERINFO(state, userInfoParm) {
		let $cookies = Vue.prototype.$cookies;
    // sessionStorage.setItem('account', newUserInfo.account);
    let userInfo = {
      id: userInfoParm.bizUser.id,
      // name: userInfoParm.bizUser.name,
      account: userInfoParm.bizUser.account,
      // isBuyer: userInfoParm.bizUser.isBuyer,
      isParnter: userInfoParm.bizUser.isParnter,
      // isSeller: userInfoParm.bizUser.isSeller,
      areaCode: userInfoParm.bizUser.areaCode,
      mobile: userInfoParm.bizUser.mobile,

      // registerTime: userInfoParm.bizUser.registerTime,
      // oldLoginTime: userInfoParm.bizUser.oldLoginTime,
      avatar: userInfoParm.bizUser.avatar,

      enterpriseState:  userInfoParm.bizUser.enterpriseState,
      // shopState:  userInfoParm.bizUser.shopState,

      enterpriseId: userInfoParm.enterpriseId,
      enterpriseName: userInfoParm.enterpriseName,

      partnerId: userInfoParm.partnerId,
      // partnerName: userInfoParm.partnerName,

      // buyerId: userInfoParm.buyerId,
      // buyerName: userInfoParm.buyerName,
      // sellerId: userInfoParm.sellerId,
      // sellerName: userInfoParm.sellerName,

      shopId: userInfoParm.shopId,
      // shopName: userInfoParm.shopName,
      isWh: userInfoParm.isWh,
      whCusNo: userInfoParm.whCusNo,

    };


    //

    // try {
      // sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
      // setCookie('userInfo', JSON.stringify(userInfo));
      // let userInfoStr = JSON.stringify(userInfo);
      // console.log('userInfoStr',userInfoStr);
      // Vue.prototype.$cookies.set("userInfo",userInfoStr,cookieDate);
      

      if(userInfo.id){
        $cookies.set("hy_userInfo_id",userInfo.id,cookieDate);
      }
      // Vue.prototype.$cookies.set("hy_userInfo_name",userInfo.name,cookieDate);
      Vue.prototype.$cookies.set("hy_userInfo_account",userInfo.account,cookieDate);
      $cookies.set("hy_userInfo_isParnter",userInfo.isParnter,cookieDate);
      $cookies.set("hy_userInfo_areaCode",userInfo.areaCode,cookieDate);
      $cookies.set("hy_userInfo_mobile",userInfo.mobile,cookieDate);
      // Vue.prototype.$cookies.set("hy_userInfo_registerTime",userInfo.registerTime,cookieDate);
      // Vue.prototype.$cookies.set("hy_userInfo_oldLoginTime",userInfo.oldLoginTime,cookieDate);
      $cookies.set("hy_userInfo_avatar",userInfo.avatar,cookieDate);
      $cookies.set("hy_userInfo_enterpriseState",userInfo.enterpriseState,cookieDate);
      // Vue.prototype.$cookies.set("hy_userInfo_shopState",userInfo.shopState,cookieDate);
      $cookies.set("hy_userInfo_enterpriseId",userInfo.enterpriseId,cookieDate);
      $cookies.set("hy_userInfo_enterpriseName",userInfo.enterpriseName,cookieDate);
      $cookies.set("hy_userInfo_partnerId",userInfo.partnerId,cookieDate);
      // Vue.prototype.$cookies.set("hy_userInfo_partnerName",userInfo.partnerName,cookieDate);
      $cookies.set("hy_userInfo_shopId",userInfo.shopId,cookieDate);
      // Vue.prototype.$cookies.set("hy_userInfo_shopName",userInfo.shopName,cookieDate);
      $cookies.set("hy_userInfo_isWh",userInfo.isWh,cookieDate);
      $cookies.set("hy_userInfo_whCusNo",userInfo.whCusNo,cookieDate);
      // Vue.prototype.$cookies.set("userInfo_id",userInfo.id,cookieDate);
      // Vue.prototype.$cookies.set("userInfo_name",userInfo.name,cookieDate);

      // sessionStorage.setItem('role', JSON.stringify(role));
    // } catch (err) {
    //   console.log(err);
    // }
    state.userInfo = userInfo;
    // state.role = role;
  },
  //更新头像
  UPDATE_USERHEAD(state, avatar) {
		let $cookies = Vue.prototype.$cookies;
    // $cookies.set("hy_user_whAuth",whAuth,cookieDate);
    // state.whAuth = whAuth;

    $cookies.set("hy_userInfo_avatar",avatar,cookieDate);
    // $cookies.set("hy_userInfo_whCusNo",userInfo.whCusNo,cookieDate);
    state.userInfo.avatar = avatar;
    // state.userInfo.whCusNo = userInfo.whCusNo;
  },
  //从本对象格式中，更新用户登录信息
  // UPDATE_USERINFO_SELF(state, userInfoParm) {
  //   let userInfo = userInfoParm;
  //   // try {
  //     // sessionStorage.setItem('userInfo', JSON.stringify(userInfoParm));
  //     // setCookie('userInfo', JSON.stringify(userInfoParm));
  //     // Vue.prototype.$cookies.set("userInfo",JSON.stringify(userInfo),cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_id",userInfo.id,cookieDate);
  //     // Vue.prototype.$cookies.set("hy_userInfo_name",userInfo.name,cookieDate);
  //     // Vue.prototype.$cookies.set("hy_userInfo_account",userInfo.account,cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_isParnter",userInfo.isParnter,cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_areaCode",userInfo.areaCode,cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_mobile",userInfo.mobile,cookieDate);
  //     // Vue.prototype.$cookies.set("hy_userInfo_registerTime",userInfo.registerTime,cookieDate);
  //     // Vue.prototype.$cookies.set("hy_userInfo_oldLoginTime",userInfo.oldLoginTime,cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_avatar",userInfo.avatar,cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_enterpriseState",userInfo.enterpriseState,cookieDate);
  //     // Vue.prototype.$cookies.set("hy_userInfo_shopState",userInfo.shopState,cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_enterpriseId",userInfo.enterpriseId,cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_enterpriseName",userInfo.enterpriseName,cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_partnerId",userInfo.partnerId,cookieDate);
  //     // Vue.prototype.$cookies.set("hy_userInfo_partnerName",userInfo.partnerName,cookieDate);
  //     Vue.prototype.$cookies.set("hy_userInfo_shopId",userInfo.shopId,cookieDate);
  //     // Vue.prototype.$cookies.set("hy_userInfo_shopName",userInfo.shopName,cookieDate);
  //     // sessionStorage.setItem('role', JSON.stringify(role));
  //   // } catch (err) {
  //   //   console.log(err);
  //   // }
  //   state.userInfo = userInfoParm;
  //   // state.userInfo = userInfo;
  //   // state.role = role;
  // },

  //删除用户登录信息
  USER_SIGNOUT(state) {
		let $cookies = Vue.prototype.$cookies;
    // sessionStorage.removeItem('account');
    // sessionStorage.removeItem('userInfo');
    // sessionStorage.removeItem('role');
    // sessionStorage.removeItem('shopInfo');
    //登出
    // Vue.prototype.$http.post(Vue.prototype.$urlConfig.HyLoginOut,{}).then(response => {
    //   console.log('登出', response);
    // }).catch(err => {
    //   console.error(err);
    // });
    // return;
    // delCookie('userInfo');
    // let $cookies = Vue.prototype.$cookies;
    // Vue.prototype.$cookies.remove('userInfo');
    $cookies.remove('hy_userInfo_id');
    // Vue.prototype.$cookies.remove('hy_userInfo_name');
    Vue.prototype.$cookies.remove('hy_userInfo_account');
    $cookies.remove('hy_userInfo_isParnter');
    $cookies.remove('hy_userInfo_areaCode');
    $cookies.remove('hy_userInfo_mobile');
    // Vue.prototype.$cookies.remove('hy_userInfo_registerTime');
    // Vue.prototype.$cookies.remove('hy_userInfo_oldLoginTime');
    $cookies.remove('hy_userInfo_avatar');
    $cookies.remove('hy_userInfo_enterpriseState');
    // Vue.prototype.$cookies.remove('hy_userInfo_shopState');
    $cookies.remove('hy_userInfo_enterpriseId');
    $cookies.remove('hy_userInfo_enterpriseName');
    $cookies.remove('hy_userInfo_partnerId');
    // Vue.prototype.$cookies.remove('hy_userInfo_partnerName');
    $cookies.remove('hy_userInfo_shopId');
    // Vue.prototype.$cookies.remove('hy_userInfo_shopName');
    // delCookie('shopInfo');
    $cookies.remove('hy_userInfo_shopId');

    $cookies.remove('hy_userInfo_isWh');
    $cookies.remove('hy_userInfo_whCusNo');


    // $cookies.remove("hy_user_whAuth");

    Object.keys(state).forEach(k => Vue.delete(state, k))
  }
}

const getters = {
  // getWhAuth: state => state.whAuth,
  // getWhAuth() {
  //   let $cookies = Vue.prototype.$cookies;
  //   state.whAuth = $cookies.get('hy_userInfo_isWh');
  //   return state.whAuth;
  // },
  getUserInfo() {
		let $cookies = Vue.prototype.$cookies;
    // state.userInfo = {
    // 	"account":sessionStorage.getItem('account'),
    // 	// "password":sessionStorage.getItem('password'),
    // 	// "userType":sessionStorage.getItem('userType'),
    // 	// "token":sessionStorage.getItem('token'),

    // 	// "otherData":JSON.parse(sessionStorage.getItem('otherData')),
    // };
    // if (sessionStorage.getItem('userInfo')) {
    if(!!state.userInfo && !!state.userInfo.id){
      return state.userInfo;
    } else {
      //如果不存在从缓存中读取
      // if(!state.userInfo || !state.userInfo.id){
        // let $cookies = Vue.prototype.$cookies;
        let userInfo = {
          id:$cookies.get('hy_userInfo_id'),
          // name:Vue.prototype.$cookies.get('hy_userInfo_name'),
          account:Vue.prototype.$cookies.get('hy_userInfo_account'),
          isParnter:$cookies.get('hy_userInfo_isParnter'),
          areaCode:$cookies.get('hy_userInfo_areaCode'),
          mobile:$cookies.get('hy_userInfo_mobile'),
          // registerTime:Vue.prototype.$cookies.get('hy_userInfo_registerTime'),
          // oldLoginTime: Vue.prototype.$cookies.get('hy_userInfo_oldLoginTime'),
          avatar:$cookies.get('hy_userInfo_avatar'),
          enterpriseState:$cookies.get('hy_userInfo_enterpriseState'),
          // shopState:Vue.prototype.$cookies.get('hy_userInfo_shopState'),
          enterpriseId:$cookies.get('hy_userInfo_enterpriseId'),
          enterpriseName:$cookies.get('hy_userInfo_enterpriseName'),
          partnerId:$cookies.get('hy_userInfo_partnerId'),
          // partnerName:Vue.prototype.$cookies.get('hy_userInfo_partnerName'),
          shopId:$cookies.get('hy_userInfo_shopId'),

          isWh:$cookies.get('hy_userInfo_isWh'),
          whCusNo:$cookies.get('hy_userInfo_whCusNo'),
          // shopName:Vue.prototype.$cookies.get('hy_userInfo_shopName'),
        };
         state.userInfo = userInfo;
       // }
      return state.userInfo;
    }

  },
  //若缓存用户信息和cookie不一样，从cookie中更新最新登录信息
  // updataCacheUserInfo(){
  //   let userInfo = {};

  //   // try {
  //   //     userInfoParm = JSON.parse(Vue.prototype.$cookies.get('userInfo'));
  //   //   } catch (err) {
  //   //     console.log('err',err);
  //   //   }
  //     console.log('state.userInfo',state.userInfo);
  //     console.log('userInfo_id',Vue.prototype.$cookies.get('hy_userInfo_id'));
  //   if(!!Vue.prototype.$cookies.get('hy_userInfo_id') && (!state.userInfo || state.userInfo.id!==Vue.prototype.$cookies.get('hy_userInfo_id'))){
  //   // if(!state.userInfo || Vue.prototype.$cookies.get('userInfo_id') || state.userInfo.id!==Vue.prototype.$cookies.get('userInfo_id')){
  //   // if(Vue.prototype.$cookies.get('userInfo_id')){

  //     // if(Vue.prototype.$cookies.get('userInfo_id')){
  //       userInfo = {
  //         id:Vue.prototype.$cookies.get('hy_userInfo_id'),
  //         // name:Vue.prototype.$cookies.get('hy_userInfo_name'),
  //         // account:Vue.prototype.$cookies.get('hy_userInfo_account'),
  //         isParnter:Vue.prototype.$cookies.get('hy_userInfo_isParnter'),
  //         areaCode:Vue.prototype.$cookies.get('hy_userInfo_areaCode'),
  //         mobile:Vue.prototype.$cookies.get('hy_userInfo_mobile'),
  //         // registerTime:Vue.prototype.$cookies.get('hy_userInfo_registerTime'),
  //         // oldLoginTime: Vue.prototype.$cookies.get('hy_userInfo_oldLoginTime'),
  //         avatar:Vue.prototype.$cookies.get('hy_userInfo_avatar'),
  //         enterpriseState:Vue.prototype.$cookies.get('hy_userInfo_enterpriseState'),
  //         // shopState:Vue.prototype.$cookies.get('hy_userInfo_shopState'),
  //         enterpriseId:Vue.prototype.$cookies.get('hy_userInfo_enterpriseId'),
  //         enterpriseName:Vue.prototype.$cookies.get('hy_userInfo_enterpriseName'),
  //         partnerId:Vue.prototype.$cookies.get('hy_userInfo_partnerId'),
  //         // partnerName:Vue.prototype.$cookies.get('hy_userInfo_partnerName'),
  //         shopId:Vue.prototype.$cookies.get('hy_userInfo_shopId'),
  //         // shopName:Vue.prototype.$cookies.get('hy_userInfo_shopName'),
  //       };
  //       // console.log('userInfo',userInfo);
  //       // console.log('userInfo_avatar',Vue.prototype.$cookies.get('userInfo_avatar'));
  //       if(userInfo.id){
  //         Vue.prototype.$cookies.set("hy_userInfo_id",userInfo.id,cookieDate);
  //       }
  //       // Vue.prototype.$cookies.set("hy_userInfo_name",userInfo.name,cookieDate);
  //       // Vue.prototype.$cookies.set("hy_userInfo_account",userInfo.account,cookieDate);
  //       if(userInfo.isParnter){
  //         Vue.prototype.$cookies.set("hy_userInfo_isParnter",userInfo.isParnter,cookieDate);
  //       }

  //       Vue.prototype.$cookies.set("hy_userInfo_areaCode",userInfo.areaCode,cookieDate);
  //       Vue.prototype.$cookies.set("hy_userInfo_mobile",userInfo.mobile,cookieDate);
  //       // Vue.prototype.$cookies.set("hy_userInfo_registerTime",userInfo.registerTime,cookieDate);
  //       // Vue.prototype.$cookies.set("hy_userInfo_oldLoginTime",userInfo.oldLoginTime,cookieDate);

  //       if(userInfo.avatar){
  //         Vue.prototype.$cookies.set("hy_userInfo_avatar",userInfo.avatar,cookieDate);
  //       }
  //       if(userInfo.enterpriseState){
  //         Vue.prototype.$cookies.set("hy_userInfo_enterpriseState",userInfo.enterpriseState,cookieDate);
  //       }
  //       // if(userInfo.shopState){
  //       //   Vue.prototype.$cookies.set("hy_userInfo_shopState",userInfo.shopState,cookieDate);
  //       // }
  //       if(userInfo.enterpriseId){
  //         Vue.prototype.$cookies.set("hy_userInfo_enterpriseId",userInfo.enterpriseId,cookieDate);
  //       }
  //       Vue.prototype.$cookies.set("hy_userInfo_enterpriseName",userInfo.enterpriseName,cookieDate);
  //       Vue.prototype.$cookies.set("hy_userInfo_partnerId",userInfo.partnerId,cookieDate);
  //       // Vue.prototype.$cookies.set("hy_userInfo_partnerName",userInfo.partnerName,cookieDate);
  //       if(userInfo.shopId){
  //         Vue.prototype.$cookies.set("hy_userInfo_shopId",userInfo.shopId,cookieDate);
  //       }
  //       // Vue.prototype.$cookies.set("hy_userInfo_shopName",userInfo.shopName,cookieDate);

  //       // console.log('userInfo_avatar',Vue.prototype.$cookies.get('userInfo_avatar'));
  //     // }

  //   }
  //   state.userInfo = userInfo;
  //   return state.userInfo;
  // }

}

const actions = {
  //更新仓库权限信息
  UPDATE_WH_AUTH: ({ commit }, value) => {
    commit('UPDATE_WH_AUTH', value);
  },

  //更新用户信息,从登录
  UPDATE_USERINFO: ({ commit }, user) => {
    commit('UPDATE_USERINFO', user);
  },

  //更新用户头像
  UPDATE_USERHEAD: ({ commit }, avatar) => {
	commit('UPDATE_USERHEAD', avatar);
    // console.log(avatar);
    // state.userInfo.avatar = avatar;
    // // console.log(state.userInfo);
    // if (state.userInfo && state.userInfo.id) {
    //   commit('UPDATE_USERINFO_SELF', state.userInfo);
    // }

  },
  //更新店铺信息
  // UPDATE_SHOPINFO: ({ commit }, shop) => {
  //   commit('UPDATE_SHOPINFO', shop);
  // },
  //更新退出
  USER_SIGNOUT: ({ commit }) => {
    commit('USER_SIGNOUT');
  },

}

export default {
  state,
  mutations,
  getters,
  actions
}
